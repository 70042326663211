import React from 'react'
import PropTypes from 'prop-types'
/* import Img from 'gatsby-image' */
import { graphql }  from 'gatsby'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import H4 from '../components/H4'
import Button from '../components/ContainedButton'
import OutlineButton from '../components/OutlinedButton'
import { btnContainedNav } from '../components/styles/ContainedButton.styles'

import { Container } from '../components/styles/Layout.styles'
import { 
  styledNamePage,
  header
} from '../components/styles/InternalPage.styles'
import Div from '../components/Div/Div.styles'
export const PageTemplate = ({
  data
}) => {
  console.log(data)
  return (
    <section css={styledNamePage}>
      <Container>
        <Row
          flexDirection={['column']}
          css={header}
        >
          <Column
            width={[1]}
          >
            <H4>
              <span style={{ textAlign: 'center' }}>Buttons Effects</span>
            </H4>
          </Column>
          <Column
            width={[1]}
          >
            <Button
              type='btnEnviar'
              Link='/Buttons'
            >
              Explorar opções de Nuvem
            </Button>
            <Div 
              style={{
                marginBottom: 20
              }}
            />
            <div
              css={btnContainedNav}
              style={{
                fontWeight: 600,
                letterSpacing: 'normal',
                lineHeight: 1.43,
                padding: '12px 16px 12px',
                width: 156,
                textTransform: 'capitalize',
                color: '#f9fafa',
                outline: 'none',
              }}
            >
              Quero Comprar
            </div>
            <Div 
              style={{
                marginBottom: 20
              }}
            />
            <Button
              type='btnContainedSmall'
              Link='/contatos'
            >
              Fale conosco
            </Button>
            <Div 
              style={{
                marginBottom: 20
              }}
            />
            <OutlineButton
              Link='/Buttons'
            >
              Explorar opções de Nuvem
            </OutlineButton>
          </Column>
        </Row>
      </Container>
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({data}) => {
  return (
    <Layout>
      <Seo title='Nossos Serviços' />
      <PageTemplate
        title='Nossos Serviços'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "ilustra-o-home-1@3x.png" }) {
      childImageSharp {
        fluid(maxWidth: 487) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default StaticPage
